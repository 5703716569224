import classNames from "classnames"
import { Link } from "gatsby"
import React, { Fragment, useState } from "react"
import Logo from "../assets/images/Logo_Colour.png"

const menuItems = {
  products: "Our Products",
  events: "Events",
  blog: "Blog",
  contact: "Contact Us",
}

const getWindowDimension = () => {
  const width = window
    ? window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    : null
  const height = window
    ? window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight
    : null
  return { width, height }
}

const MobileProducts = ({ setIsChecked, isOpen, toggleDropdown }) => {
  const handleClick = () => {
    setIsChecked(false)
    toggleDropdown()
  }

  return (
    <div
      className={classNames("navigation-mobile__dropdown", {
        ["navigation-mobile__visible"]: isOpen,
      })}
    >
      <Link
        onClick={handleClick}
        to={`/rv`}
        className="navigation-mobile__link"
      >
        Resolve Virtually
      </Link>
      <Link
        onClick={handleClick}
        to={`/cdr`}
        className="navigation-mobile__link"
      >
        Commercial Dispute Resolution
      </Link>
      <Link
        onClick={handleClick}
        to={`/gr`}
        className="navigation-mobile__link"
      >
        Grievance Redressal
      </Link>
    </div>
  )
}

const MobileMenu = () => {
  const [isChecked, setIsChecked] = useState(false)
  const [isOpen, setIsOpen] = useState(false)

  const toggleDropdown = () => {
    setIsOpen(prevState => !prevState)
  }

  return (
    <div className="navigation-mobile">
      <input
        type="checkbox"
        className="navigation-mobile__checkbox"
        id="nav-toggle"
        onChange={event => setIsChecked(event.currentTarget.checked)}
        checked={isChecked}
      />
      <label for="nav-toggle" className="navigation-mobile__button">
        <span className="navigation-mobile__icon">&nbsp;</span>
      </label>
      <div className="navigation-mobile__background">&nbsp;</div>
      <nav className="navigation-mobile__nav">
        <ul className="navigation-mobile__list">
          <li
            className="navigation-mobile__item"
            onClick={e => setIsChecked(false)}
          >
            <Link to="/" className="navigation-mobile__link">
              Home
            </Link>
          </li>
          {Object.keys(menuItems).map(key => {
            const isProduct = key === "products"

            return (
              <li className="navigation-mobile__item" key={key}>
                {isProduct ? (
                  <Fragment>
                    <p
                      onClick={toggleDropdown}
                      className="navigation-mobile__dropdown-anchor"
                    >
                      {menuItems[key]}
                    </p>
                    <MobileProducts
                      setIsChecked={setIsChecked}
                      isOpen={isOpen}
                      toggleDropdown={toggleDropdown}
                    />
                  </Fragment>
                ) : (
                  <Link
                    onClick={e => setIsChecked(false)}
                    to={`/${key}`}
                    className="navigation-mobile__link"
                  >
                    {menuItems[key]}
                  </Link>
                )}
              </li>
            )
          })}
        </ul>
      </nav>
    </div>
  )
}

const ProductDropdown = () => {
  return (
    <div className="navigation__dropdown">
      <Link to="/rv" className="navigation__navitem ">
        Resolve Virtually
      </Link>
      <Link to="/cdr" className="navigation__navitem ">
        Commercial Dispute Resolution
      </Link>
      <Link to="/gr" className="navigation__navitem ">
        Grievance Redressal
      </Link>
    </div>
  )
}

const Nav = ({ navClass }) => {
  const isBrowser = typeof window !== "undefined"
  const [isHidden, setHidden] = React.useState(false)
  const { height, width: initialWidth } = isBrowser
    ? getWindowDimension()
    : { height: 0, width: 0 }

  const [width, setWidth] = React.useState(initialWidth)

  const scrollHandler = () => {
    if (window.scrollY > 1) {
      setHidden(true)
    } else {
      setHidden(false)
    }
  }

  const handleResize = () => {
    console.log("firing")
    const { height, width } = getWindowDimension()
    setWidth(width)
  }

  React.useEffect(() => {
    setWidth(width)
    window.addEventListener("resize", handleResize)
    // return window.removeEventListener("resize", handleResize)
  }, [isBrowser])

  React.useEffect(() => {
    document.addEventListener("scroll", scrollHandler)
    return () => {
      document.removeEventListener("scroll", scrollHandler)
    }
  }, [])

  if (width && width < 900) return <MobileMenu />

  return (
    <div
      className={classNames("navigation", {
        "navigation--hidden": isHidden,
        "navigation--blue": navClass,
      })}
    >
      <div className="navigation__nav">
        <div className="navigation__brand u-margin-left-medium">
          <Link to="/">
            <img src={Logo} alt="webnyay logo" />
          </Link>
        </div>
        <div className="navigation__grow"></div>

        <div className="navigation__navitems">
          {Object.keys(menuItems).map(menuItem => {
            const isProduct = menuItem === "products"
            return (
              <Link
                key={menuItem}
                className={classNames("navigation__navitem u-margin-X-large", {
                  ["navigation__dropdown-anchor"]: isProduct,
                })}
                to={`/${isProduct ? "" : menuItem}`}
              >
                {menuItems[menuItem]}
                {isProduct && <ProductDropdown />}
              </Link>
            )
          })}
        </div>
        <a href="https://app.webnyay.in" target="__blank">
          <button className="btn btn--contained btn--small">Log in</button>
        </a>
      </div>
    </div>
  )
}

export default Nav
