import PropTypes from "prop-types"
import React from "react"
import "../assets/scss/_main.scss"
import Footer from "./footer"
import Nav from "./nav"
import Seo from "./seo"

const Layout = ({ children, navClass, title, ogImage, ogDescription }) => {
  const defaultTitle = "Resolve Complaints And Disputes Outside Court"

  return (
    <>
      <Seo
        title={title ? title : defaultTitle}
        ogImage={ogImage}
        ogDescription={ogDescription}
      />
      <Nav navClass={navClass} />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
